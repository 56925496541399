
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AuthCheckView extends Vue {
  /**
   * Render empty view
   */
  private render() {
    //
  }
}
